import Search from '../../assets/search.png';
import './Header.css';

function Header() {
  return (
    <div className="header">
        <h1>Pemetaan</h1>
        <div className="header-menu">
            <img src={Search} alt="" />
        </div>
    </div>
  )
}

export default Header
