import Sidebar from "./component/sidebar/Sidebar";
import Header from "./component/header/Header";
import "./App.css";


function App() {
  return (
    <div className="App">
      <Sidebar />
      <Header />
    </div>
  );
}

export default App;
