import './Sidebar.css';

function Sidebar() {
  return (
    <div className="sidebar">
      <h1>AgriSIG</h1>
      <div className="sidebar-menu-container">
        <h4>Modul</h4>
        <div className="sidebar-menu active">Pemetaan</div>
        <div className="sidebar-menu">Dashboard</div>
        <div className="sidebar-menu">Statistics</div>
      </div>
    </div>
  )
}

export default Sidebar
